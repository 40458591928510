var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.discussionsData)?_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("#")]),_c('th',{attrs:{"colspan":"4"}},[_vm._v(_vm._s(_vm.$t("Discussions.data")))]),_vm._m(0)]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("general.image")))]),_c('th',[_vm._v(_vm._s(_vm.$t("general.code")))]),_c('th',{staticClass:"cell-lg"},[_vm._v(_vm._s(_vm.$t("general.title")))]),_c('th',[_vm._v(_vm._s(_vm.$t("general.state")))])])]),_c('tbody',_vm._l((_vm.discussionsData),function(discussion,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(++index + _vm.filterData.currentIndex))]),_c('td',[_c('img',{staticClass:"item-img-table",attrs:{"src":_vm.fullPathFileFromServer(
                discussion.discussionMediaPath,
                _vm.defaultImg
              ),"onerror":("this.src='" + _vm.defaultImg + "'")}})]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(discussion.fullCode)))]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(discussion.discussionTitleCurrent))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(discussion.discussionClosedStateTypeNameCurrent))+" ")]),_c('td',{staticClass:"fmenu-item-container"},[_c('FloatingMenu',[(_vm.checkPrivilege(_vm.hasDiscussionComment()))?_c('li',[_c('router-link',{attrs:{"to":{
                  name: 'DiscussionComments',
                  params: {
                    educationalGroupToken: _vm.filterData.educationalGroupToken,
                    discussionToken: discussion.discussionToken,
                    parentDiscussionCommentToken: ' ',
                  },
                },"title":_vm.$t('DiscussionComments.modelName')}},[_c('img',{attrs:{"src":require("@/assets/images/discussionComments.svg")}})])],1):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('info')},on:{"click":function($event){_vm.setDiscussionData(discussion);
                  _vm.openBottomSheet('DiscussionInfo');}}},[_c('img',{attrs:{"src":require("@/assets/images/info.svg")}})])]),_c('li',[_c('button',{attrs:{"title":_vm.$t('general.qrCode')},on:{"click":function($event){_vm.setDiscussionData(discussion);
                  _vm.openBottomSheet('DiscussionQRCode');}}},[_c('img',{attrs:{"src":require("@/assets/images/qr-code.svg")}})])]),(_vm.checkPrivilege(_vm.hasDiscussionEdit()))?_c('li',[_c('button',{attrs:{"title":_vm.$t('edit')},on:{"click":function($event){_vm.setDiscussionData(discussion);
                  _vm.openBottomSheet('DiscussionUpdate');}}},[_c('img',{attrs:{"src":require("@/assets/images/pencil.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasDiscussionFinaleDelete()))?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.DiscussionDelete",modifiers:{"DiscussionDelete":true}}],attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setDiscussionData(discussion)}}},[_c('img',{attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasDiscussionChangeActivationType()))?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.DiscussionChangeActivationType",modifiers:{"DiscussionChangeActivationType":true}}],attrs:{"title":_vm.$t('changeActivationType')},on:{"click":function($event){return _vm.setDiscussionData(discussion)}}},[_c('img',{attrs:{"src":require("@/assets/images/changeActivationType.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasDiscussionChangeClosedState()))?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.DiscussionChangeCloseState",modifiers:{"DiscussionChangeCloseState":true}}],attrs:{"title":_vm.$t('Discussions.changeClosedState')},on:{"click":function($event){return _vm.setDiscussionData(discussion)}}},[_c('img',{attrs:{"src":require("@/assets/images/type.svg")}})])]):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('actionsData.modelName')},on:{"click":function($event){_vm.setDiscussionData(discussion);
                  _vm.openBottomSheet('ActionsData');}}},[_c('img',{attrs:{"src":require("@/assets/images/actions-data.svg")}})])])])],1)])}),0)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{attrs:{"rowspan":"2"}},[_c('i',{staticClass:"fas fa-sliders-h"})])}]

export { render, staticRenderFns }
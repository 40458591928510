var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row custom-cards"},_vm._l((_vm.discussionsData),function(discussion,index){return _c('CustomCard',{key:index,attrs:{"className":'col-sm-6 col-md-4 col-lg-3',"title":discussion.discussionTitleCurrent,"description":discussion.fullCode,"imagePath":discussion.discussionMediaPath,"defaultImg":_vm.defaultImg,"index":++index + _vm.filterData.currentIndex}},[(_vm.checkPrivilege(_vm.hasDiscussionComment()))?_c('li',[_c('router-link',{attrs:{"to":{
          name: 'DiscussionComments',
          params: {
            educationalGroupToken: _vm.filterData.educationalGroupToken,
            discussionToken: discussion.discussionToken,
            parentDiscussionCommentToken: '',
          },
        },"title":_vm.$t('DiscussionComments.modelName')}},[_c('img',{attrs:{"src":require("@/assets/images/discussionComments.svg")}})])],1):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('info')},on:{"click":function($event){_vm.setDiscussionData(discussion);
          _vm.openBottomSheet('DiscussionInfo');}}},[_c('img',{attrs:{"src":require("@/assets/images/info.svg")}})])]),_c('li',[_c('button',{attrs:{"title":_vm.$t('general.qrCode')},on:{"click":function($event){_vm.setDiscussionData(discussion);
          _vm.openBottomSheet('DiscussionQRCode');}}},[_c('img',{attrs:{"src":require("@/assets/images/qr-code.svg")}})])]),(_vm.checkPrivilege(_vm.hasDiscussionEdit()))?_c('li',[_c('button',{attrs:{"title":_vm.$t('edit')},on:{"click":function($event){_vm.setDiscussionData(discussion);
          _vm.openBottomSheet('DiscussionUpdate');}}},[_c('img',{attrs:{"src":require("@/assets/images/pencil.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasDiscussionFinaleDelete()))?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.DiscussionDelete",modifiers:{"DiscussionDelete":true}}],attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setDiscussionData(discussion)}}},[_c('img',{attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasDiscussionChangeActivationType()))?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.DiscussionChangeActivationType",modifiers:{"DiscussionChangeActivationType":true}}],attrs:{"title":_vm.$t('changeActivationType')},on:{"click":function($event){return _vm.setDiscussionData(discussion)}}},[_c('img',{attrs:{"src":require("@/assets/images/changeActivationType.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasDiscussionChangeClosedState()))?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.DiscussionChangeCloseState",modifiers:{"DiscussionChangeCloseState":true}}],attrs:{"title":_vm.$t('Discussions.changeClosedState')},on:{"click":function($event){return _vm.setDiscussionData(discussion)}}},[_c('img',{attrs:{"src":require("@/assets/images/type.svg")}})])]):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('actionsData.modelName')},on:{"click":function($event){_vm.setDiscussionData(discussion);
          _vm.openBottomSheet('ActionsData');}}},[_c('img',{attrs:{"src":require("@/assets/images/actions-data.svg")}})])])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.discussion.discussionImagePath,
            _vm.discussion.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.discussion.discussionImageIsDefault &&
          _vm.checkPrivilege(_vm.hasDiscussionDeleteImage())},on:{"changeValue":function($event){_vm.discussion.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.discussion.fullCode,"title":_vm.$t('Discussions.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.discussion.fullCode = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-discussionTitleAr"),"errors":_vm.errors_discussionTitleAr,"value":_vm.discussion.discussionTitleAr,"title":_vm.$t('Discussions.titleAr'),"imgName":'discussions.svg'},on:{"changeValue":function($event){_vm.discussion.discussionTitleAr = $event;
            _vm.$v.discussion.discussionTitleAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-discussionTitleEn"),"errors":_vm.errors_discussionTitleEn,"value":_vm.discussion.discussionTitleEn,"title":_vm.$t('Discussions.titleEn'),"imgName":'discussions.svg'},on:{"changeValue":function($event){_vm.discussion.discussionTitleEn = $event;
            _vm.$v.discussion.discussionTitleEn.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-discussionTitleUnd"),"value":_vm.discussion.discussionTitleUnd,"title":_vm.$t('Discussions.titleUnd'),"imgName":'discussions.svg'},on:{"changeValue":function($event){_vm.discussion.discussionTitleUnd = $event}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-discussionDescriptionAr"),"errors":_vm.errors_discussionDescriptionAr,"value":_vm.discussion.discussionDescriptionAr,"title":_vm.$t('Discussions.descriptionAr'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.discussion.discussionDescriptionAr = $event;
            _vm.$v.discussion.discussionDescriptionAr.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-discussionDescriptionEn"),"errors":_vm.errors_discussionDescriptionEn,"value":_vm.discussion.discussionDescriptionEn,"title":_vm.$t('Discussions.descriptionEn'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.discussion.discussionDescriptionEn = $event;
            _vm.$v.discussion.discussionDescriptionEn.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-discussionDescriptionUnd"),"value":_vm.discussion.discussionDescriptionUnd,"title":_vm.$t('Discussions.descriptionUnd'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.discussion.discussionDescriptionUnd = $event}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-discussionNotes"),"value":_vm.discussion.discussionNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.discussion.discussionNotes = $event}}})],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }